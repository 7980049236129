<!--<mat-toolbar color="primary" fxLayoutAlign="space-between center">-->
<!--  &lt;!&ndash; <span>E-lab</span> &ndash;&gt;-->
<!--  <img class="logo" src="assets/img/elab-logo-small.svg" />-->
<!--  <div><b style="font-size: smaller; " class="text-user">Korisnik: {{username}}</b>&nbsp;&nbsp; <button mat-button (click)="logout()">Odjavi se</button></div>-->
<!--</mat-toolbar>-->
<mat-toolbar color="primary" fxLayoutAlign="space-between center">
  <!-- <span>E-lab</span> -->
  <img class="logo" src="assets/img/elab-logo-small.svg" />
  <div fxLayout="row" fxLayoutAlign="center center">
    <b style="font-size: smaller; padding-bottom: 1px" class="text-user">Korisnik: {{username}}</b>&nbsp;&nbsp;
    <button mat-button (click)="logout()">Odjavi se</button>
  </div>
</mat-toolbar>
