import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstitutionsService } from '@app/core/services/institutions.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from '../shared/components/breadcrumb/breadcrumb.component';
import { Institution } from '@app/shared/types/Institutions.types';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@Component({
  selector: 'app-institutions',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    RouterModule,
    BreadcrumbComponent,
    MatPaginatorModule,
    PipesModule,
  ],
  templateUrl: './institutions.component.html',
  styleUrls: ['./institutions.component.css'],
})
export class InstitutionsComponent {
  institutions = new MatTableDataSource<Institution>([]);
  pageIndex: number = 0;
  pageSize: number = 20;
  length: number;

  displayedColumns: string[] = [
    'institutionCode',
    'institutionName',
    'ParentInstitution',
    'institutionType',
    'location',
    'lastTransactionTime',
  ];

  constructor(private institutionsService: InstitutionsService) {}

  ngOnInit(): void {
    this.getInstitutions(this.pageIndex, this.pageSize);
  }

  handlePageEvent(e: PageEvent): void {
    this.length = e.length;
    this.getInstitutions(e.pageIndex * 20, this.pageSize);
  }

  getInstitutions(pageIndex: number, pageSize: number): void {
    this.institutionsService
      .getInstitutions(pageIndex, pageSize)
      .subscribe(
        (res) => (this.institutions = new MatTableDataSource<Institution>(res))
      );
  }
}
